#products-head {
  height: 60vh;
  width: 100%;
  display: flex;
  background: url("/public/images/products-top.jpg") center center no-repeat fixed;
  background-size: cover;
  justify-content: center;
  align-items: center;
  color: white;
  gap: 20px;
  flex-direction: column;
}

#products-head h2 {
  position: relative;
  font-size: 2.3rem;
  word-spacing: 6px;
  animation: slideInLeft 0.7s ease-in-out forwards;
}

#products-head p {
  position: relative;
  width: 60%;
  font-size: 1rem;
  word-spacing: 8px;
  font-family: "Tiro Devanagari Hindi", serif;
  text-align: center;
  animation: slideInRight 0.7s ease-in-out forwards;
}

.filter-container {
  width: 100vw;
  display: flex;
  justify-content: space-between;
  padding: 10px 5px;
  gap: 5px;
}

.search-bar {
  flex: 1;
  padding: 10px;
  font-size: 16px;
}

.category-filter {
  padding: 10px;
  font-size: 16px;
}

.product-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.no-products{
  text-align: center;
  margin: 30px;
}

@media only screen and (max-width: 480px) {
  .search-bar {
    flex: 1;
    padding: 10px;
    font-size: 12px;
  }
  
  .category-filter {
    padding: 10px;
    font-size: 12px;
  }
}