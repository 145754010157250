/* Navbar.css */
@import url("../animations/animation.css");

/* General navbar styling */
.navbar {
  position: sticky;
  top: 10%;
  background-color: white;
  padding: 0 2rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.1);
  z-index: 2;
  height: 15vh;
  user-select: none;
  animation: slideInTop 0.7s ease-in-out forwards;
}

.hamburger {
  cursor: pointer;
  display: flex;
  gap: 6px;
  flex-direction: column;
  display: none;
}

.hamburger-dash {
  width: 25px;
  background-color: #333638;
  height: 2px;
  transition: transform 0.5s;
}

.start-dash {
  transform: rotateZ(45deg);
  position: absolute;
  top: 50%;
}

.middle-dash {
  visibility: hidden;
}

.end-dash {
  transform: rotateZ(-45deg);
  position: absolute;
  top: 50%;
}

.navbar-menu {
  list-style-type: none;
  display: flex;
  gap: 4rem;
  margin: 0;
  padding: 0;
}

.navbar-logo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 30px;
  height: 100%;
}

.navbar-logo img {
  width: 200px;
  margin-top: 7px;
}

.navbar-link {
  display: inline-block;
  color: #333638;
  text-decoration: none;
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  position: relative;
  transition: color 0.3s ease, transform 0.1s ease;
}

.navbar-link:hover {
  color: #76a970;
  /* Green color on hover */
}

.navbar-link::after {
  content: '';
  display: block;
  width: 0;
  height: 2.5px;
  border-radius: 45px;
  background: #76a970;
  /* Green color */
  transition: width 0.3s;
  margin-top: 0.3rem;
}

.navbar-link:active {
  transform: scale(0.9);
}

.active {
  color: #76a970;
}

.active::after {
  width: 100%;
}

/* Basic styles */
.toggle-switch {
  position: relative;
  width: 60px;
  height: 30px;
  margin-left: 140px;
}

.toggle-input {
  display: none;
  /* Hide the default checkbox */
}

/* Label styled as the toggle */
.toggle-label {
  position: absolute;
  display: flex;
  cursor: pointer;
  background-color: #76a970;
  align-items: center;
  justify-content: center;
  gap: 3px;
  width: 100%;
  height: 100%;
  border-radius: 30px;
  transition: background-color 0.3s ease;
}

/* The slider inside the toggle */
.toggle-label:before {
  content: '';
  position: absolute;
  height: 22px;
  width: 22px;
  background-color: white;
  border-radius: 50%;
  top: 4px;
  left: 4px;
  transition: transform 0.3s ease;
}

.toggle-input:checked+.toggle-label {
  background-color: #e9da5d;
}

.toggle-input:checked+.toggle-label:before {
  transform: translateX(30px);
}

.toggle-input:checked+.toggle-label #mr-text {
  visibility: hidden;
}

.toggle-input:not(:checked)+.toggle-label #en-text {
  visibility: hidden;
}

.toggle-text {
  font-size: 0.8rem;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  color: white;
}

/* Media query for tablets in portrait and landscape */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .navbar {
    padding: 0 10px;
  }

  .navbar-menu {
    gap: 3rem;
  }

  .navbar-logo img {
    width: 150px;
  }

  .toggle-switch {
    margin-left: 90px;
  }

}

@media only screen and (max-width: 768px) {
  .hamburger {
    display: flex;
  }

  .navbar {
    padding: 0;
  }

  .navbar-link:active {
    transform: none;
  }

  .navbar-menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 15vh;
    left: -100%;
    background-color: #fff;
    width: 40%;
    height: 85vh;
    padding-top: 22px;
    gap: 6px;
    box-shadow: inset 2px 2px 2px 1px rgba(0, 0, 0, 0.1), 2px 2px 2px 1px rgba(0, 0, 0, 0.1);
    transition: left 0.5s ease;
  }

  .navbar-menu.open {
    left: 0;
  }

  .navbar-link {
    display: inline-block;
    width: 100%;
    color: #333638;
    font-weight: 700;
    padding: 7px 20px;
    transition: background-color 0.3s ease, color 0.3s ease;
  }

  .navbar-link.active {
    background-color: #76a970;
    color: white;
  }

  .navbar-link:hover {
    color: white;
  }

  .navbar-link::after {
    display: none;
  }

  .toggle-switch {
    margin: 0;
  }
}

@media only screen and (max-width: 480px) {
  .navbar {
    height: 13vh;
    width: 100vw;
  }

  .toggle-switch {
    width: 50px;
    height: 26px;
  }

  .navbar-menu {
    top: 13vh;
    width: 70%;
    height: 87vh;
  }

  .navbar-logo img {
    width: 140px;
    margin-top: 7px;
  }

  .toggle-label {
    gap: 3px;
    width: 100%;
    height: 100%;
    border-radius: 30px;
    gap: 5px;
    transition: background-color 0.3s ease;
  }

  .toggle-label:before {
    height: 20px;
    width: 20px;
    top: 3px;
    left: 3px;
  }

  .toggle-input:checked+.toggle-label:before {
    transform: translateX(24px);
  }

  .toggle-text {
    font-size: 0.6rem;
  }

  .navbar-logo {
    gap: 10px;
  }
}