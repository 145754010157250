@import url("/src/animations/animation.css");

.embla {
  overflow: hidden;
  user-select: none;
}

.embla__container {
  display: flex;
}

.embla__slide {
  flex: 0 0 100%;
  width: 100%;
}

.embla__slide img {
  display: block;
  width: 100%;
  height: 85vh;
}

.scroll__buttons {
  position: absolute;
  display: flex;
  width: 100%;
  justify-content: space-between;
  top: 50%;
}

.scroll__button {
  background-color: rgba(255, 255, 255, 0.3);
  border: none;
  cursor: pointer;
  padding: 20px;
  font-weight: bold;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.scroll__button::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  margin-top: 0.1rem;
  background-color: white;
  transition: width 0.3s, transform 0.3s;
}

.scroll__button:hover::after {
  width: 100%;
}

.embla__prev {
  border-radius: 0 50% 50% 0;
}

.embla__next {
  border-radius: 50% 0 0 50%;
}

.slide-content {
  position: absolute;
  display: flex;
  width: 100%;
  top: 0;
  height: 83vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.slide-header {
  position: relative;
  left: 10%;
  font-family: "Tiro Devanagari Hindi", serif;
  text-align: center;
  font-style: italic;
  font-weight: 700;
  color: white;
  font-size: 1.6rem;
  margin-bottom: 30px;
  animation: slideInLeft 0.7s ease-in-out forwards;
}

.slide-body {
  text-align: center;
  font-weight: 900;
  color: white;
  font-size: 4.5rem;
  margin-bottom: 30px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  word-spacing: 6px;
  animation: scaleUp 0.7s ease-in-out forwards;
}

.slide-footer {
  position: relative;
  right: 10%;
  font-weight: 700;
  width: 70%;
  text-align: center;
  color: white;
  font-size: 2.3rem;
  word-spacing: 6px;
  margin-bottom: 40px;
  animation: slideInRight 0.7s ease-in-out forwards;
}

#home-about-container {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
}

.home-about {
  display: flex;
  width: 80%;
  margin: 60px 0;
  justify-content: center;
  align-items: center;
}

.home-about-image {
  position: relative;
  width: 40%;
  height: 75vh;
  background: url("/public/images/shop-image.jpg") no-repeat center / cover;
  border-radius: 8px;
  animation: slideInLeft ease-in-out forwards;
  animation-timeline: view();
  animation-range: entry 0% cover 40%;
}

.home-about-content {
  position: relative;
  width: 50%;
  height: 100%;
  padding: 0 0 0 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  animation: slideInRight ease-in-out forwards;
  animation-timeline: view();
  animation-range: entry 0% cover 40%;
}

.home-about-content h5 {
  color: #76a970;
  font-size: 1.4rem;
  font-weight: 600;
  word-spacing: 8px;
}

.home-about-content h1 {
  color: #333638;
  font-size: 4rem;
  font-weight: 600;
  word-spacing: 10px;
  margin: 8px 0;
}

.home-about-content h2 {
  color: #333638;
  font-size: 2.9rem;
  font-weight: normal;
  word-spacing: 8px;
}

.home-about-properties {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 40px 0;
  color: #33363869;
}

.home-about-properties span {
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: 600;
  transition: color 0.2s ease-in;
}

.home-about-properties span:hover {
  color: #76a970;
}

.active-property {
  color: #76a970;
}

.home-about-properties span::after {
  content: '';
  display: block;
  width: 0;
  height: 2.5px;
  border-radius: 45px;
  background: #76a970;
  /* Green color */
  transition: width 0.3s;
  margin-top: 0.3rem;
}

span.active-property::after {
  color: #76a970;
  width: 100%;
}

.home-about-content p {
  display: none;
  margin-bottom: 5px;
}

p.active-property-content {
  display: inline-block;
  min-height: 18vh;  
  font-size: calc(12px + 0.4vw);
  font-family: "Tiro Devanagari Hindi", serif;
  color: #33363869;
}

.about-quality {
  display: flex;
  width: 80%;
  margin: 50px 0;
  justify-content: center;
  align-items: center;
  gap: 35px;
  text-align: center;
}

.about-quality-content{
  display: flex;
  flex-direction: column;
  position: relative;
  animation: scaleUp ease-in-out forwards;
  animation-timeline: view();
  animation-range: entry 0% cover 40%;
}

.about-quality-content-header span {
  font-size: 60px;
  font-weight: 700;
  margin-right: 17px;
  color: #33363849;
}

.about-quality-content-footer h2 {
  word-spacing: 800px;
  margin-bottom: 15px;
  font-weight: 600;
  color: #333638;
}

.about-quality-content-footer p {
  font-size: calc(12px + 0.4vw);
  color: #333638c1;
  font-family: "Tiro Devanagari Hindi", serif;
}

#home-mid {
  height: 70vh;
  width: 100%;
  margin: 50px 0;
  background: url("/public/images/home-mid-background.jpg") center center no-repeat fixed;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  gap: 40px;
  flex-direction: column;
}

#home-mid h2 {
  text-align: center;
  font-size: calc(30px + 0.4vw);
  word-spacing: 8px;
  position: relative;
  animation: slideInLeft ease-in-out forwards;
  animation-timeline: view();
  animation-range: entry 0% cover 40%;
}

#home-mid p {
  width: 55%;
  font-family: "Tiro Devanagari Hindi", serif;
  font-size: calc(15px + 0.4vw);
  text-align: center;
  position: relative;
  animation: slideInRight ease-in-out forwards;
  animation-timeline: view();
  animation-range: entry 0% cover 40%;
}

.home-testimonials-container {
  display: flex;
  width: 100%;
  margin: 10px 0 60px 0;
  justify-content: center;
  align-items: center;
  padding: 40px 60px;
  gap: 30px;
}

.home-testimonials-header {
  margin-top: 100px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  position: relative;
  text-align: center;
  animation: slideInTop ease-in-out forwards;
  animation-timeline: view();
  animation-range: entry 0% cover 40%;
}

.home-testimonials-header h4 {
  color: #76a970;
  font-size: calc(14px + 0.4vw);
  word-spacing: 10px;
}

.home-testimonials-header h2 {
  font-size: calc(50px + 0.4vw);
  word-spacing: 10px;
  color: #333638;
}

.home-testimonials {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  padding: 40px 30px;
  position: relative;
  animation: scaleUp ease-in-out forwards;
  animation-timeline: view(); 
  animation-range: entry 0% cover 40%;
}

.home-testimonials h1 {
  font-size: 55px;
  color: #33363849;
}

.home-testimonials p {
  font-size: calc(12px + 0.4vw);
  color: #333638c1;
  font-family: "Tiro Devanagari Hindi", serif;
  text-align: center;
}

.testimonials-client {
  display: flex;
  margin-top: 40px;
  gap: 17px;
}

.testimonials-client img {
  border-radius: 50%;
  width: 70px;
  height: 70px;
}

.testimonials-client div{
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.testimonials-client span {
  font-style: italic;
  font-size: calc(12px + 0.4vw);
  color: #76a970;
}

.testimonials-client h4 {
  color: #333638;
  font-size: calc(12px + 0.4vw);
}

.home-brands{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 60px 0;
  margin: 50px 0 90px 0;
  gap: 60px;
  background-color: #f9f9f9;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.home-brands img{
  height: auto;
  width: 150px;
  position: relative;
  animation: slideInLeft ease-in-out forwards;
  animation-timeline: view();
  animation-range: entry 0% cover 25%;
}

.home-brands-1{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-evenly;
}

.home-brands-2{
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  justify-content: space-evenly;
}

.home-testimonials-all{
  height: fit-content;
}

.home-map-container{
  height: 60vh;
  width: 70%;
  margin: 40px auto 70px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  position: relative;
  animation: scaleUp ease-in-out forwards;
  animation-timeline: view();
  animation-range: entry 0% cover 40%;
}

#home-map{
  height: 100%;
  width: 100%;
  border: none;
  border-radius: 8px;
}

/* Media query for tablets in portrait and landscape */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .slide-header {
    margin-bottom: 15px;
  }

  .slide-body {
    font-size: 3.3rem;
    margin-bottom: 15px;
  }

  .slide-footer {
    font-size: 2rem;
    margin-bottom: 30px;
  }

  .home-about-image {
    height: 50vh;
  }

  .home-about-content h5 {
    font-size: 1.6vw;
  }

  .home-about-content h1 {
    font-size: 4vw;
    margin: 4px 0;
  }

  .home-about-content h2 {
    font-size: 2.8vw;
  }

  .home-about-properties {
    margin: 20px 0;
  }

  .home-about-properties span {
    font-size: 14px;
  }

  .home-about-content p {
    margin-bottom: 0;
  }

  p.active-property-content{
    font-size: 16px;
  }

  .about-quality{
    flex-direction: column;
    gap: 30px;
  }

  .home-map-container{
    width: 100vw;
  }
}

/* Media query for mobile devices */
@media only screen and (max-width: 768px) {
  .embla__slide img {
    object-fit: cover;
  }

  .slide-header {
    margin-bottom: 15px;
    font-size: 1.2rem;
  }

  .slide-body {
    font-size: 1.8rem;
    margin-bottom: 15px;
  }

  .slide-footer {
    font-size: 1.4rem;
    margin-bottom: 30px;
  }

  .scroll__button {
    padding: 13px;
  }

  .home-about {
    flex-direction: column-reverse;
    gap: 40px;
    width: 100%;
    margin: 60px 0;
  }

  .home-about-image {
    width: 80%;
  }

  .home-about-content {
    width: 100%;
    height: 100%;
    padding: 0 70px;
  }

  .home-about-content h5 {
    font-size: 17px;
  }

  .home-about-content h1 {
    font-size: 38px;
    margin: 8px 0;
  }

  .home-about-content h2 {
    font-size: 24px;
  }

  .home-about-properties {
    margin: 25px 0;
  }

  .home-about-properties span {
    font-size: 19px;
  }

  .home-about-content p {
    margin-bottom: 0;
  }

  p.active-property-content {
    min-height: 13vh;
    font-size: 17px;
  }
  
  .about-quality{
    flex-direction: column;
  }

  .home-testimonials-container{
    flex-direction: column;
  }

  .home-map-container{
    width: 100vw;
  }
}

@media only screen and (max-width: 480px) {
  .scroll__button {
    padding: 8px;
  }

  .embla__slide img {
    height: 87vh;
  }

  .slide-header {
    font-size: 1rem;
  }

  .slide-body {
    font-size: 1.4rem;
  }

  .slide-footer {
    font-size: 1.2rem;
  }

  .home-about {
    flex-direction: column-reverse;
    gap: 40px;
    width: 100%;
    margin: 40px 0;
  }

  .home-about-image {
    width: 90%;
    height: 40vh;
  }

  .home-about-content {
    width: 100%;
    height: 100%;
    padding: 0 20px;
    align-items: center;
  }

  .home-about-properties {
    width: 100%;
  }

  p.active-property-content {
    min-height: 15vh;
    font-size: 1rem;
    max-height: fit-content;
  }

  .home-about-properties span {
    font-size: 5vw;
  }

  .home-brands-1 {
    flex-direction: column;
    
    gap: 20px;
    justify-content: center;
    align-items: center;
  }

  .home-brands-1 img{
    width: 100px;
    height: auto;
  }

  .home-brands-2{
    display: none;
  }
}