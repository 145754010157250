.card {
    width: 280px;
    height: wrap-content;
    padding: 2em;
    background: #f5f5f5;
    border-radius: 8px;
    position: relative;
    overflow: visible;
    margin: 20px;
    transition: .3s ease;
}

.card-img {
    height: 60%;
    width: 100%;
    aspect-ratio: 3/2;
    object-fit: contain;
    border-radius: .5rem;
}

.card-info {
    padding-top: 10%;
}

.card-footer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
}

/*Text*/
.text-title {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 1.3em;
    line-height: 1.5;
    text-align: center;
}

.text-price {
    font-family: "Tiro Devanagari Hindi", serif;
    text-align: center;
    font-weight: 700;
    font-size: 1rem;
    color: #76a970;
}

/*Hover*/
.card:hover {
    transform: translateY(-15px);
    background-color: white;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}