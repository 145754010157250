@import url("/src/animations/animation.css");

.footer {
  background-color: #444a4d;
  color: white;
  padding: 80px 0;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  font-family: "Poppins", sans-serif;
  position: relative;
  animation: slideInBottom ease-in-out forwards;
  animation-timeline: view();
  animation-range: entry 0% cover 40%;
}

.open-text {
  display: block;
}

.footer-break-line {
  height: 1px;
  width: 100%;
  background-color: #696e71;
  margin: 20px 0;
}

.social {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.soc-icon {
  height: 2.5vh;
  transition: transform 0.3s ease;
  cursor: pointer;
}

.soc-icon:hover {
  transform: scale(1.2);
  transition: transform 0.3s ease;
}

.contacts {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 25vw;
}

.contacts img {
  height: 2.5vh;
}

.contacts-content {
  display: flex;
  align-items: center;
  gap: 10px;
}

.contacts h2 {
  margin: 20px 0;
  font-weight: 500;
}

.quicklinks {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.footer-links {
  text-decoration: none;
  color: white;
}

.quicklinks h2 {
  margin: 20px 0;
  font-weight: 500;
}

.quicklinks ul {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.quicklinks li {
  list-style-type: none;
  position: relative;
  padding-left: 20px;
  cursor: pointer;
  transition: transform 0.2s ease-in;
}

.quicklinks li:hover {
  transform: translateX(13px);
}

.quicklinks li::before {
  position: absolute;
  top: 40%;
  left: 0;
  content: "";
  height: 3px;
  width: 10px;
  border-radius: 8px;
  background-color: white;
  transition: width 0.2s ease, transform 0.2s ease;
}

.quicklinks li:hover::before {
  width: 23px;
  border-radius: 8px;
  transform: translateX(-13px);
}

.copyright {
  background-color: #31383b;
  color: #717476;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  padding: 40px 0;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .footer{
    padding: 30px 0;
  }

  .logos{
    flex: 0 0 100%;
    text-align: center;
    padding-bottom: 20px;
  }

  .social{
    justify-content: center;
  }

  .contacts h2{
    margin: 30px 0 0 0;
    text-align: center;
  }

  .quicklinks h2{
    margin: 30px 0 0 0;
  }
}

@media only screen and (max-width: 480px) {

  .logos{
    flex: 0 0 100%;
    text-align: center;
    padding-bottom: 20px;
  }

  .contacts{
    flex: 0 0 100%;
  }

  .contacts-content{
    flex-direction: column;
    text-align: center;
  }

  .quicklinks h2{
    margin: 40px 0 0 0;
  }
}