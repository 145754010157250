@import url("../animations/animation.css");

.modern-button {
    position: relative;
    bottom: 10%;
    padding: 25px 60px;
    font-size: calc(10px + 0.4vw);
    font-family: "Poppins", sans-serif;
    letter-spacing: 1px;
    font-weight: bold;
    color: white;
    background-color: #76a970;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.4);
    transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease, color 0.3s ease;
    animation: slideInBottom 0.7s ease-in-out forwards;
  }
  
  .modern-button:hover {
    background-color: #e9da5d;
    color: #333638;
  }
  
  .modern-button:active {
    transform: scale(0.9);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .modern-button:focus {
    outline: none;
  }
  
  .modern-button:disabled {
    background-color: #b0bec5;
    cursor: not-allowed;
  }
  
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
        .modern-button{
          padding: 20px 45px;
        }
  }

  @media only screen and (max-width: 768px) {
    .modern-button{
      padding: 15px 35px;
    }

    .modern-button:hover {
      background-color: #e9da5d;
      color: #000;
    }
  }

  @media only screen and (max-width: 480px) {
    .modern-button{
      padding: 10px 30px;
      font-size: 10px;
    }
  }