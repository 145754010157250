@import url("/src/animations/animation.css");

#about-head {
  height: 60vh;
  width: 100%;
  display: flex;
  background: url("/public/images/about-top.jpg") center center no-repeat fixed;
  background-size: cover;
  justify-content: center;
  align-items: center;
  color: white;
  gap: 20px;
  flex-direction: column;
}

#about-head h2 {
  position: relative;
  font-size: 2.3rem;
  word-spacing: 6px;
  animation: slideInLeft 0.7s ease-in-out forwards;
}

#about-head p {
  position: relative;
  width: 60%;
  font-size: 1rem;
  word-spacing: 8px;
  font-family: "Tiro Devanagari Hindi", serif;
  text-align: center;
  animation: slideInRight 0.7s ease-in-out forwards;
}

#about-main-container {
  height: auto;
  width: 100%;
  margin: 8vh 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.about-main {
  display: flex;
  flex-direction: row-reverse;
  width: 80%;
  margin: 60px 0;
  justify-content: center;
  align-items: center;
}

.about-main-header {
  display: flex;
}

.about-main-header h1 {
  font-size: 5vw;
  color: #33363849;
}

.about-main-header h2 {
  margin: auto auto auto 25px;
  font-size: 3vw;
  font-weight: 600 !important;
}

.about-main-image {
  width: 45%;
  height: 70vh;
  background: url("/public/images/narayangiriMaharaj.jpg") no-repeat center 15% / cover;
  border-radius: 8px;
  position: relative;
  animation: slideInRight ease-in-out forwards;
  animation-timeline: view();
  animation-range: entry 0% cover 40%;
}

.about-main-content {
  width: 50%;
  height: 100%;
  padding: 0 70px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  animation: slideInLeft ease-in-out forwards;
  animation-timeline: view();
  animation-range: entry 0% cover 40%;
}

.about-main-content h2 {
  color: #333638;
  font-size: 2.6rem;
  font-weight: 600;
  word-spacing: 10px;
  text-align: center;
}

.about-main-properties {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 40px 0;
  color: #33363869;
}

.about-main-properties span {
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: 600;
  transition: color 0.2s ease-in;
}

.about-main-properties span:hover {
  color: #76a970;
}

.active-property {
  color: #76a970;
}

.about-main-properties span::after {
  content: '';
  display: block;
  width: 0;
  height: 2.5px;
  border-radius: 45px;
  background: #76a970;
  transition: width 0.3s;
  margin-top: 0.3rem;
}

span.active-property::after {
  color: #76a970;
  width: 100%;
}

.about-main-content p {
  display: none;
  margin-bottom: 5px;
}

p.active-property-content {
  display: inline-block;
  min-height: 18vh;  
  font-size: calc(12px + 0.4vw);
  font-family: "Tiro Devanagari Hindi", serif;
  color: #33363869;
  padding-bottom: 20px;
}

.history-container {
  display: flex;
  padding-top: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  width: 100%;
  height: 90vh;
  background: url("/public/images/about-history.jpg") center center no-repeat;
  background-size: cover;
  color: white;
}

.history-container h1 {
  font-size: 3.2rem;
  word-spacing: 4px;
  position: relative;
  animation: slideInBottom ease-in-out forwards;
  animation-timeline: view();
  animation-range: entry -40% cover 30%;
}

.history-box {
  display: flex;
  width: 97%;
  justify-content: space-between;
  align-items: center;
  position: relative;
  animation: slideInLeft ease-in-out forwards;
  animation-timeline: view();
  animation-range: entry 0% cover 40%;
}

.history-container h1 {
  text-align: center;
}

.history-box-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 20px;
  justify-content: center;
  align-items: center;
  width: 24%;
  height: 35vh;
  background-color: rgba(255, 255, 255, 0.707);
  color: #333638;
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.history-box-content-text{
  font-family: "Tiro Devanagari Hindi", serif;
}

.history-box-content-header {
  font-size: 30px;
  font-weight: 500;
  color: black;
}

.history-timeline-container{
  width: 97%;
  height: 16%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  animation: slideInBottom ease-in-out forwards;
  animation-timeline: view();
  animation-range: entry -60% cover 15%;
}

.history-timeline-before{
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 10%;
  padding-left: 7px;
}

.timeline-square{
  width: 10px;
  height: 10px;
  background-color: white;
  transform: rotate(45deg) translateY(6px);
}

.history-timeline {
  background-color: white;
  width: 100%;
  height: 90%;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #76a970;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.history-timeline-blocks {
  display: flex;
  width: 25%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.timeline-blocks-line {
  height: 1px;
  background-color: #76a970;
  width: 100%;
}

.timeline-blocks-content {
  padding: 0 25px;
  font-size: 18px;
  font-weight: 700;
}

.owner-container{
  width: 100%;
  height: 100vh;
  margin: 40px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 35px;
  margin: 100px 0;
}

.owner-header{
  font-size: 3.2rem;
  word-spacing: 4px;
  text-align: center;
  color: #333638;
  position: relative;
  animation: slideInBottom ease-in-out forwards;
  animation-timeline: view();
  animation-range: entry -70% cover 30%;
}

.owner-name{
  font-size: 1.7vw;
  font-weight: 500;
  text-align: center;
  position: relative;
  animation: slideInBottom ease-in-out forwards;
  animation-timeline: view();
  animation-range: entry -200% cover 15%;
}

.owner-image{
  height: 60%;
  width: auto;
  border-radius: 8px;
  position: relative;
  animation: scaleUp ease-in-out forwards;
  animation-timeline: view();
  animation-range: entry 0% cover 30%;
}

.owner-info{
  padding: 0 15%;
  text-align: center;
  color: #333638;
  font-family: "Tiro Devanagari Hindi", serif;
  position: relative;
  animation: slideInBottom ease-in-out forwards;
  animation-timeline: view();
  animation-range: entry -100% cover 15%;
}

.shop-stats-container{
  display: flex;
  padding: 0 20px;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 60vh;
  background: url("/public/images/about-count.jpg") center center no-repeat fixed;
  background-size: cover;
}

.shop-stats-block{
  text-align: center;
  color: white;position: relative;
  animation: slideInBottom ease-in-out forwards;
  animation-timeline: view();
  animation-range: entry -10% cover 40%;
}

.stats-head{
  font-size: 3.5vw;
  font-weight: 700;
}

.stats-line{
  width: 30%;
  height: 1px;
  justify-self: center;
  background-color: white;
  margin: 20px 0;
}

.stats-name{
  font-size: 1.5vw;
  font-weight: 500;
  width: 60%;
  margin: auto;
  text-align: center;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  p.active-property-content{
    font-size: 16px;
  }

  .about-main-image{
    height: 50vh;
  }

  .about-main-content h1 {
    font-size: 7vw;
    margin: 4px 0;
  }

  .about-main-content h2 {
    font-size: calc(29px + 0.4vw);
  }

  .about-main-properties span {
    font-size: 14px;
  }

  .history-container{
    gap: 20px;
  }

  .home-about-content p {
    margin-bottom: 0;
  }

  .history-box-content{
    height: 40vh;
  }

  .history-box-content-header{
    font-size: calc(20px + 0.4vw);
  }

  .owner-name{
    font-size: calc(20px + 0.4vw);
  }
  
  .owner-info{
    font-size: calc(15px + 0.4vw);
  }

}

@media only screen and (max-width: 768px) {

  .about-main {
    flex-direction: column-reverse;
    gap: 40px;
    width: 100%;
    margin: 60px 0;
  }

  .about-main-image {
    width: 80%;
  }

  .about-main-content {
    width: 100%;
    height: 100%;
    padding: 0 70px;
  }

  .about-main-content h1 {
    font-size: 38px;
    margin: 8px 0;
  }

  .about-main-content h2 {
    font-size: 24px;
  }

  .about-main-properties {
    margin: 25px 0;
  }

  .about-main-properties span {
    font-size: 19px;
  }

  .about-main-content p {
    margin-bottom: 0;
  }

  p.active-property-content {
    min-height: 13vh;
    font-size: 17px;
  }

  .history-container{
    gap: 10px;
  }

  .home-about-content p {
    margin-bottom: 0;
  }

  .history-container{
    padding-top: 0;
  }

  .history-box{
    flex-direction: column;
    width: 90vw;
    gap: 10px;
  }

  .history-box-content{
    width: 100%;
    height: 17vh;
  }

  .history-box-content-header{
    font-size: calc(20px + 0.4vw);
  }

  .history-box-content-text{
    font-size: calc(12px + 0.4vw);
  }

  .history-timeline-container{
    display: none;
  }

  .owner-name{
    font-size: calc(25px + 0.4vw);
  }
  
  .owner-info{
    font-size: calc(15px + 0.4vw);
  }

  .stats-head{
    font-size: calc(25px + 0.4vw);
    font-weight: 700;
  }
  
  .stats-line{
    width: 30%;
    height: 1px;
    justify-self: center;
    background-color: white;
    margin: 20px 0;
  }
  
  .stats-name{
    font-size: calc(15px + 0.4vw);
  }
}

@media only screen and (max-width: 480px) {
  .about-main-image{
    width: 90%;
    height: 40vh;
  }

  .about-main {
    flex-direction: column-reverse;
    gap: 40px;
    width: 100%;
    margin: 40px 0;
  }

  .about-main-image {
    width: 90%;
    height: 40vh;
  }

  .about-main-content {
    width: 100%;
    height: 100%;
    padding: 0 20px;
    align-items: center;
  }

  .about-main-properties {
    width: 100%;
  }

  p.active-property-content {
    min-height: 15vh;
    font-size: 1rem;
    max-height: fit-content;
  }

  .about-main-properties span {
    font-size: 5vw;
  }

  .history-box{
    flex-direction: column;
    width: 95vw;
    gap: 5px;
  }

  .history-box-content{
    width: 100%;
    height: 19vh;
  }

  .history-timeline-container{
    display: none;
  }

  .owner-container{
    margin: 80px 0;
  }

  .owner-name{
    font-size: calc(25px + 0.4vw);
  }

  .owner-image{
    width: 100%;
    height: auto;
  }

  .shop-stats-container{
    display: flex;
    flex-direction: column;
  }
  
  .stats-head{
    font-size: calc(20px + 0.4vw);
  }
  
  .stats-line{
    margin: 10px 0;
  }
  
  .stats-name{
    font-size: calc(12px + 0.4vw);
  }
}