#gallery-head {
    height: 60vh;
    width: 100%;
    display: flex;
    background: url("/public/images/gallery-top.jpg") center center no-repeat fixed;
    background-size: cover;
    justify-content: center;
    align-items: center;
    color: white;
    gap: 20px;
    flex-direction: column;
    font-family: "Poppins", sans-serif;
}

#gallery-head h2 {
    position: relative;
    font-size: 2.3rem;
    word-spacing: 6px;
    animation: slideInLeft 0.7s ease-in-out forwards;
}

#gallery-head p {
    position: relative;
    width: 60%;
    font-size: 1rem;
    word-spacing: 8px;
    text-align: center;
    animation: slideInRight 0.7s ease-in-out forwards;
}

.gallery-images-box {
    width: 1400px;
    margin: 40px auto;
    columns: 4;
    column-gap: 20px;
}

.image-box {
    width: 100%;
    margin-bottom: 10px;
    break-inside: avoid;
}

.image-box img {
    max-width: 100%;
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

@media (max-width: 1410px) {
    .gallery-images-box {
        width: calc(100% - 40px);
        columns: 3;
    }
}

@media (max-width: 768px) {
    .gallery-images-box {
        columns: 2;
    }
}

@media (max-width: 480px) {
    .gallery-images-box {
        columns: 1;
    }
}