.dashboard-container{
    display: flex;
    flex-wrap: wrap;
}

.modern-form {
    background: #ffffff;
    padding: 20px 30px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 500px;
    box-sizing: border-box;
}

.form-group {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 20px;
}

.modern-form input[type="text"],
.modern-form input[type="number"],
.modern-form textarea {
    width: 100%;
    padding: 12px 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    box-sizing: border-box;
    transition: border 0.3s ease;
}

.modern-form input[type="text"]:focus,
.modern-form input[type="number"]:focus,
.modern-form textarea:focus {
    border-color: #4caf50;
    outline: none;
}

.modern-form textarea {
    resize: vertical;
    height: 100px;
}

.modern-form input[type="file"] {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.dashboard-container button {
    width: 100%;
    padding: 12px 15px;
    background: #4caf50;
    color: white;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.dashboard-container button:hover {
    background: #45a049;
}




/* Delete Products Container */
.delete-product {
    background: #ffffff;
    padding: 20px 30px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 600px;
    margin: 20px auto;
    box-sizing: border-box;
  }
  
  .delete-product h2 {
    font-size: 24px;
    color: #333333;
    margin-bottom: 20px;
    text-align: center;
  }
  
  /* Product List */
  .product-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .product-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    margin-bottom: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    background: #f9f9f9;
    transition: background 0.3s ease, box-shadow 0.3s ease;
  }
  
  .product-item:hover {
    background: #f1f1f1;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Product Details */
  .product-details {
    font-size: 16px;
    color: #555555;
    flex-grow: 1;
  }
  
  /* Delete Button */
  .delete-button {
    padding: 8px 12px;
    font-size: 14px;
    color: #ffffff;
    background: #e74c3c;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .delete-button:hover {
    background: #c0392b;
  }
  
  /* Responsive Design */
  @media (max-width: 600px) {
    .delete-product {
      padding: 15px 20px;
    }
  
    .product-item {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
    }
  
    .product-details {
      font-size: 14px;
    }
  
    .delete-button {
      align-self: flex-end;
    }
  }
  