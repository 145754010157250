@keyframes slideInLeft {
    0% {
        left: -10%;
        opacity: 0;
    }

    100% {
        left: 0;
        opacity: 1;
    }
}

@keyframes slideInRight {
    0% {
        right: -10%;
        opacity: 0;
    }

    100% {
        right: 0;
        opacity: 1;
    }
}

@keyframes slideInBottom {
    0% {
        bottom: -10%;
        opacity: 0;
    }

    100% {
        bottom: 0;
        opacity: 1;
    }
}

@keyframes slideInTop {
    0% {
        top: -10%;
        opacity: 0;
    }

    100% {
        top: 0;
        opacity: 1;
    }
}

@keyframes scaleUp {
    0% {
        transform: scale(0.7);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}